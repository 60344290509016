import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FilteringNavBarConfig } from '@models/filtering-nav-bar/filtering-nav-bar.interface';
import { Store } from '@ngrx/store';
import { partnerWordingVariations } from '@stores/partner/partner.selectors';
import { selectThemes } from '@stores/themes/themes.selectors';
import { Observable, combineLatest, filter, map } from 'rxjs';

const MIN_THEMES_TO_SHOW_TABS = 2;

export const navConfigResolver: ResolveFn<Observable<FilteringNavBarConfig | null>> = () => {
  const store = inject(Store);

  return combineLatest([
    store.select(selectThemes).pipe(filter((themes) => themes.length > 0)),
    store.select(partnerWordingVariations),
  ]).pipe(
    map(([themes, wordings]) =>
      themes.length < MIN_THEMES_TO_SHOW_TABS
        ? null
        : [
            {
              link: [],
              label: `Tous les ${wordings.serviceDesignationPlural.toLowerCase()}`,
              queryParams: { category: undefined },
              tracker: { category: 'Services', action: 'Click', label: 'Tous les services' },
            },
            ...themes.map((theme) => ({
              link: [],
              label: theme.name,
              queryParams: { category: theme.id },
              tracker: { category: 'Services', action: 'Click', label: theme.name },
            })),
          ]
    )
  );
};
